import { useDispatch, useSelector } from 'react-redux';
import reduxStore from './store';

// Infer the `RootState` and `AppDispatch` types from the store itself
export type ReduxRootState = ReturnType<typeof reduxStore.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof reduxStore.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<ReduxRootState>();
