import clsx from 'clsx';
import { Button } from '@/components/shadcn/button';

export type TImageSize = 'small' | 'med' | 'large';

function getImageSizeClass(imgSize: TImageSize) {
  switch (imgSize) {
    case 'small':
      return 'h-[140px]';
    case 'med':
      return 'h-[170px]';
    case 'large':
      return 'h-[200px]';
    default:
      return 'h-[150px]';
  }
}

function SomethingWentWrong({
  widthClass = 'w-full',
  heightClass = 'h-96',
  imgSize = 'med',
  className = '',
  hideReloadButton = false,
  message = 'Something went wrong',
}: {
  widthClass?: string;
  heightClass?: string;
  imgSize?: TImageSize;
  className?: string;
  hideReloadButton?: boolean;
  message?: string;
}) {
  return (
    <div
      className={clsx(
        'grid place-items-center',
        widthClass,
        heightClass,
        className
      )}
    >
      <div className="flex flex-col items-center justify-center -translate-y-2/5">
        <img
          className={getImageSizeClass(imgSize)}
          src="/illustrations/no-integration.svg"
          alt={message}
        />
        <p
          className={clsx(
            'text-txt-secondary mb-10',
            imgSize === 'large' ? 'text-base' : 'text-sm'
          )}
        >
          {message}
        </p>
        {!hideReloadButton ? (
          <Button
            variant="outlinePrimary"
            onClick={() => {
              window.location.reload();
            }}
          >
            Reload Page
          </Button>
        ) : null}
      </div>
    </div>
  );
}

export default SomethingWentWrong;
