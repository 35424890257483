import { ReactNode, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ChevronLeft } from 'lucide-react';
import { Button } from '@/components/shadcn/button';
import { useAppConfig } from '../contexts/AppConfig';

interface PageHeaderProps {
  title: ReactNode;
  backUrl?: string;
  onBack?: () => void;
  actions?: ReactNode[];
  description?: string;
  containerClasses?: string;
}

function PageHeader({
  title,
  description = '',
  backUrl = '',
  onBack,
  actions,
  containerClasses,
}: PageHeaderProps) {
  const navigate = useNavigate();
  const { entityTypes } = useAppConfig();
  const [searchParams] = useSearchParams();
  const navigationContext = searchParams.has('navigation-context')
    ? JSON.parse(searchParams.get('navigation-context') ?? 'null')
    : '';
  let subTitle = useMemo(
    () =>
      Array.isArray(navigationContext?.application_ids)
        ? `${
            entityTypes.find(
              (en) =>
                en.entityType ===
                navigationContext.application_ids[0]?.entity_type
            )?.name ?? ''
          }: "${navigationContext.application_ids[0]?.name ?? ''}"`
        : '',
    [entityTypes, navigationContext]
  );
  if (navigationContext?.name !== undefined) {
    subTitle = navigationContext.name;
  }

  const hasBackAction = !!backUrl || !!onBack;
  const canGoBack = window.history.length > 1;
  return (
    <div
      data-testid="page-header-container"
      className={`flex justify-between items-center py-4 ${containerClasses}`}
    >
      <div>
        <h1 className="text-2xl font-semibold flex items-center">
          {canGoBack && hasBackAction ? (
            <Button
              variant="ghost"
              size="icon"
              className="!h-8 !w-8 mr-1"
              onClick={() => {
                if (onBack) {
                  onBack();
                } else {
                  navigate(backUrl);
                }
              }}
              data-testid="back-btn"
            >
              <ChevronLeft className="h-10 w-8 stroke-[1.5]" />
            </Button>
          ) : null}
          <p>{title}</p>
          {subTitle ? (
            <p
              data-testid="subtitle"
              className="text-sm text-foreground font-normal border-l border-muted-foreground pl-3"
            >
              {subTitle}
            </p>
          ) : null}
        </h1>
        {description ? (
          <p
            className={`leading-6 font-normal min-h-6 ${
              hasBackAction
                ? 'ml-3 text-sm text-muted-foreground'
                : 'text-sm text-muted-foreground'
            }`}
          >
            {description || null}
          </p>
        ) : null}
      </div>
      <div
        className="flex justify-start gap-4 pr-1"
        data-testid="actions-container"
      >
        {actions
          ? actions.map((action) => (
              <div key={action?.toLocaleString()}>{action}</div>
            ))
          : null}
      </div>
    </div>
  );
}

export default PageHeader;
