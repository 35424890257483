import { useEffect, useRef, useState } from 'react';
import PageHeader from 'root/src/layout/PageHeader';
import IntegrationItem, {
  IntegrationItemProps,
  IntegrationType,
} from './IntegrationItem';

const getColumnCount = (integrationCount: number, containerWidth: number) => {
  const minColumnWidth = 200;
  const maxColumns = 4;

  const columns = Math.min(
    Math.floor(containerWidth / minColumnWidth),
    maxColumns
  );

  return Math.min(columns, integrationCount);
};

const useContainerWidth = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        setWidth(containerRef.current.offsetWidth);
      }
    };

    window.addEventListener('resize', updateWidth);
    updateWidth();

    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  return { containerRef, width };
};

function Integrations() {
  const integrationServices: IntegrationItemProps[] = [
    {
      icon: '/illustrations/jira.svg',
      name: 'Jira',
      provider: 'Atlassian',
      description:
        'Jira is a proprietary product developed by Atlassian that allows bug tracking, issue tracking and agile project management.',
      enabled: true,
      type: IntegrationType.JIRA,
    },
    {
      icon: '/illustrations/slack.svg',
      name: 'Slack',
      provider: 'Salesforce',
      description:
        'Slack is a cloud-based team communication platform developed by Slack Technologies, which has been owned by Salesforce since 2020. Slack uses a freemium model.',
      enabled: false,
      type: IntegrationType.SLACK,
    },
  ];
  const { containerRef, width } = useContainerWidth();
  const columnCount = getColumnCount(integrationServices.length, width);
  return (
    <div className="flex flex-col pr-0 h-full w-full">
      <PageHeader
        title="Integration Hub"
        description="Third-party app integration available"
      />
      <div
        ref={containerRef}
        className="gap-4 grid"
        style={{
          gridTemplateColumns: `repeat(${columnCount}, minmax(200px, 400px))`,
        }}
      >
        {integrationServices.map((item, idx) => (
          <IntegrationItem key={idx} {...item} />
        ))}
      </div>
    </div>
  );
}

export default Integrations;
