/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { EntityTypeSchema, EnumTypeSchema } from 'src/generated';
import { IFilterEntity } from 'src/utils/common';

interface IAppCommonState {
  isFilterOpen: boolean;
  entityList: EntityTypeSchema[];
  enumLabelList: EnumTypeSchema[];
  sideFilterDetails: Record<string, IFilterEntity[]>;
}

const initialState: IAppCommonState = {
  isFilterOpen: false,
  entityList: [],
  enumLabelList: [],
  sideFilterDetails: {},
};

export const appCommonSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setFilterState: (state, action: PayloadAction<boolean>) => {
      state.isFilterOpen = action.payload;
    },
    setEntityList: (state, action: PayloadAction<EntityTypeSchema[]>) => {
      if (state.entityList.length === 0) {
        state.entityList = action.payload;
      }
    },
    setEnumLabelList: (state, action: PayloadAction<EnumTypeSchema[]>) => {
      if (state.enumLabelList.length === 0) {
        state.enumLabelList = action.payload;
      }
    },
    setFilterDetails: (
      state,
      action: PayloadAction<{ filterKey: string; filters: IFilterEntity[] }>
    ) => {
      state.sideFilterDetails[action.payload.filterKey] =
        action.payload.filters;
    },
  },
});

export const {
  setFilterState,
  setEntityList,
  setEnumLabelList,
  setFilterDetails,
} = appCommonSlice.actions;

export default appCommonSlice.reducer;
