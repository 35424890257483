import './styles/styles.scss';

import { useSession, useUser } from '@descope/react-sdk';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { publicRoutesConfig } from './routes/routes';

import Toaster from '@/components/shadcn/toaster';
import { TooltipProvider } from '@/components/shadcn/tooltip';
import { LOGIN_URL } from './constants/index';
import { AppConfigProvider } from './contexts/AppConfig';
import AllRoutes from './routes/allRoutes';

export const queryClientRQ = new QueryClient({
  defaultOptions: {
    queries: { retry: false, refetchOnWindowFocus: false },
  },
});

function App() {
  const { isAuthenticated, isSessionLoading } = useSession();

  const navigate = useNavigate();

  const authIgnoreRoutes = publicRoutesConfig.map((r) => r.path);

  const { user } = useUser();

  const { pathname } = useLocation();

  useEffect(() => {
    if (!isAuthenticated && !isSessionLoading) {
      if (!authIgnoreRoutes.includes(pathname)) {
        navigate(LOGIN_URL);
      }
    }
  }, [
    pathname,
    isAuthenticated,
    isSessionLoading,
    user,
    authIgnoreRoutes,
    navigate,
  ]);

  return (
    <QueryClientProvider client={queryClientRQ}>
      <TooltipProvider delayDuration={0}>
        <AppConfigProvider>
          <div className="flex flex-col h-screen">
            {isSessionLoading ? (
              <div className=" h-full w-ful flex items-center justify-center">
                <div className="sui-loader" />
              </div>
            ) : (
              <AllRoutes isAuthenticated={isAuthenticated} />
            )}
            <Toaster />
          </div>
        </AppConfigProvider>
      </TooltipProvider>
      <ReactQueryDevtools initialIsOpen={false} buttonPosition="top-left" />
    </QueryClientProvider>
  );
}

export default App;
