import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { TopologyEntityNodeData } from './shared';

interface topologyState {
  selectedEntity: TopologyEntityNodeData | null | undefined;
  selectedSubGraphId: string | null | undefined;
}

const initialState: topologyState = {
  selectedEntity: null,
  selectedSubGraphId: null,
};

export const topologySlice = createSlice({
  name: 'topology-slice',
  initialState,
  reducers: {
    setSelectedEntity: (
      state,
      action: PayloadAction<TopologyEntityNodeData | null>
    ) => {
      if (action?.payload?.id === state.selectedEntity?.id) {
        state.selectedEntity = null;
      } else {
        state.selectedEntity = action?.payload;
      }
    },
    setSelectedSubGraphId: (
      state,
      action: PayloadAction<string | null | undefined>
    ) => {
      state.selectedSubGraphId = action.payload;
    },
  },
});

export const { setSelectedEntity, setSelectedSubGraphId } =
  topologySlice.actions;

export default topologySlice.reducer;
