import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SortParamSortOrderEnum } from 'src/generated';

interface DetailsTableState {
  sortState: {
    sort_by: string;
    sort_order: SortParamSortOrderEnum | 'UNKNOWN';
  };
  pagination: { offset: number; limit: number };
  selection: string[];
  visibleColumnIds: string[];
}

const initialState: DetailsTableState = {
  sortState: { sort_by: '', sort_order: 'UNKNOWN' },
  pagination: { offset: 0, limit: 20 },
  selection: [],
  visibleColumnIds: [],
};

const detailsTableSlice = createSlice({
  name: 'detailsTableState',
  initialState,
  reducers: {
    setSortState: (
      state,
      action: PayloadAction<{
        sort_by: string;
        sort_order: SortParamSortOrderEnum;
      }>
    ) => {
      state.sortState = action.payload;
    },
    setPagination: (
      state,
      action: PayloadAction<{ offset: number; limit: number }>
    ) => {
      state.pagination = action.payload;
    },
    setSelection: (state, action: PayloadAction<string[]>) => {
      state.selection = action.payload;
    },
    setVisibleColumnIds: (state, action: PayloadAction<string[]>) => {
      state.visibleColumnIds = action.payload;
    },
    resetTableState: (state) => {
      state.sortState = { sort_by: '', sort_order: 'UNKNOWN' };
      state.pagination = { offset: 0, limit: 20 };
      state.selection = [];
      state.visibleColumnIds = [];
    },
  },
});

export const {
  setSortState,
  setPagination,
  setSelection,
  setVisibleColumnIds,
  resetTableState,
} = detailsTableSlice.actions;
export default detailsTableSlice.reducer;
