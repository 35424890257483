import { AuthProvider as DescopeAuthProvider } from '@descope/react-sdk';
import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App.tsx';
import store from './contexts/redux/store.ts';
import { ThemeProvider } from './styles/ThemeProvider.tsx';
import ErrorBoundary from './utils/errorBoundary.tsx';

// const mockAllowedEnvs = ['development'];
async function enableMocking() {
  // if (!mockAllowedEnvs.includes(process.env.NODE_ENV ?? '')) {
  //   return;
  // }
  // const { worker } = await import('./mocks/browser');
  // return worker.start();
}

enableMocking().then(() => {
  ReactDOM.createRoot(document.getElementById('root')!).render(
    <ErrorBoundary>
      <Suspense
        fallback={
          <div className="grid place-items-center w-full h-screen">
            <div className="sui-loader" />
          </div>
        }
      >
        <Provider store={store}>
          <ThemeProvider defaultTheme="dark" storageKey="singulr-ui-theme">
            <BrowserRouter>
              <DescopeAuthProvider
                projectId={import.meta.env.VITE_DESCOPE_PROJECT_ID}
              >
                <App />
              </DescopeAuthProvider>
            </BrowserRouter>
          </ThemeProvider>
        </Provider>
      </Suspense>
    </ErrorBoundary>
  );
});
