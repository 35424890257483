import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export interface ISelectedEntity {
  id: string;
  name: string;
  queryId: string;
  entityType: number;
}

interface riskState {
  selectedEntity: ISelectedEntity | null;
}

const initialState: riskState = {
  selectedEntity: null,
};

export const riskSlice = createSlice({
  name: 'risk',
  initialState,
  reducers: {
    setSelectedEntity: (
      state,
      action: PayloadAction<ISelectedEntity | null>
    ) => {
      state.selectedEntity = action.payload;
    },
  },
});

export const { setSelectedEntity } = riskSlice.actions;

export default riskSlice.reducer;
